(function($){

	var 
		_body,
		_window
	;
	var App = {

        $header  : null,
		$menuToggle : null,
		$menu : null,
		$menuItems : null,
        lastScrolTop : false,

		init : function(){

			_body = $( 'body' );
			_window = $( window );

			App.bindHeader();
			App.bindSanfona();
			App.bindSlick();
            
            _window.scroll(function(){
                App.checkScroll();
            });

		},

        // Uncheck menu items when is top page
        checkScroll : function(){
            
            var scrollTop = _window.scrollTop();
            if( scrollTop <= 70 ){                
                App.$menuItems.removeClass( 'active' );
            }
            
            App.checkHeader();
        
        },

        checkHeader : function(){

            var scrollTop = _window.scrollTop();            
            var dir = scrollTop > this.lastScrolTop ? 'down' : 'up';

            // Initial position
            if( scrollTop < 1 ){
                this.$header.addClass( 'initial' );
            }
            else {
                this.$header.removeClass( 'initial' );
            }

            // Show or hidden
            if( scrollTop < 1 || dir === 'up' ){
                this.$header.removeClass( 'out' );
            }
            else {
                if( scrollTop > 0 && dir === 'down' ){
                    this.$header.removeClass( 'initial' ).addClass( 'out' );
                    this.closeMenu();
                }
            }

            this.lastScrolTop = scrollTop;
        
        },

        // Binding header
        bindHeader : function(){

            this.$header = $( 'body > header' ).first();
            this.$menuToggle = $( '.menu-toggle' );
            this.$menu  = $( '#menu' ).first();
            this.$menuItems  = $( 'a', this.$menu );

            // Initial header check
            App.checkHeader();
            this.$header.removeClass( 'start' );

            // Menu toggle
            this.$menuToggle.on( 'click', function(e){
                App.toggleMenu();
            });

            // Close menu
            $( 'button', this.$menu ).on( 'click', function(){
            	App.closeMenu();
            });
            $( 'a', this.$menu ).on( 'click', function(){
        
            	App.$menuItems.removeClass( 'active' );
        
            	this.$ = this.$ || $( this );
            	this.$.addClass( 'active' );
        
                App.closeMenu();
        
            });
        
        },

        // Toggle mobile menu
        toggleMenu : function(){
            if( _body.hasClass( 'menu-opened' ) ){
                this.closeMenu();
            }
            else {
                this.openMenu();
            }
        },

        // Open menu mobile
        openMenu : function(){
            this.$menuToggle.addClass( 'opened' );
            _body.addClass( 'menu-opened' );
        },

        // Close menu mobile
        closeMenu : function(){
            this.$menuToggle.removeClass( 'opened' );
            _body.removeClass( 'menu-opened' );
        },

		bindSanfona : function(){

            // Accordion
            $( '.piki-sanfona' ).pikiSanfona({       
                items: '>div',
                headers: '>h2',
                contents: '>div'
            });

		},

		bindSlick : function(){
			$( '#prazos ul' ).slick({
				infinite: false,
				draggable: false,
				addaptiveHeight: true
			});
		},

        scrollTo : function( selector ){

            var $target = typeof( selector ) === 'object' ? selector : $( selector );            
            if( !$target.length ) return;

            var newY = $target.offset().top;
            var actualY = _window.scrollTop();
            var distance = actualY - newY;
            if( distance < 0 ) distance *= -1;

            var time = ( 600 - ( distance * 0.1 ) );
            if( time < 600 ) time = 600;
            
            var offset = $target.data( 'offset' );
            if( offset ){

                try {

                    var 
                        _json = JSON.parse( offset.split( "'" ).join( '"' ) )
                        _offset = false,
                        _vWidth = App.vWidth()
                    ;

                    $.each( _json.breaks, function( _break ){
                        if( _vWidth <= _break ){
                            _offset = parseInt( this );
                            return false;
                        }
                    });

                    offset = ( !_offset ) ? parseInt( _json.initial ) : _offset;

                }
                catch( e ){
                    var _offset = parseInt( offset );
                    offset = _offset > 0 ? _offset : 0;
                }

            }
            else {
                offset = 0;
            }

            $( 'html, body' ).animate({
                scrollTop: ( newY - ( offset + 30 ) )
            }, time );      

        },

        vWidth : function vWidth(){
            return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
        }

	};
	$(function(){		
		App.init();
	});

})(jQuery);