var 
	Navigation,
	_body,
	_window
;

(function($){

	Navigation = {

		timeout : false,

		init : function(){

			// Body
			if( _body === undefined ){
				_body = $( 'body' );
			} 

			// Window
			if( _window === undefined ){
				_window = $( window );
			} 

			Navigation.bind();

		},

	    bind : function(){

	        // Bind clicks
	        _body.on( 'click', 'a', function(e){

	            this.$ = this.$ || $( this );

	            // There is no href
	            if( this.href === undefined || this.href.indexOf( '#' ) < 0 ) return true;

	            // There is no destiny
	            if( this.href === '#' ){
	                event.preventDefault();
	            }

	            // Element ID
	            elemnetID = this.href.split( '#' ).pop();

	            // Links
	            if( elemnetID.indexOf( 'http' ) === 0 ){
	                return true;
	            }

	            // Actual url
	            var $target = $( '#' + elemnetID );

	            if( $target.length ){

	                event.preventDefault();

	                // Prevent default page scroll
	                $target.attr( 'id', '' );
	                window.location.hash = elemnetID;
	                $target.attr( 'id', elemnetID );

	                // Scroll to
	                Navigation.scrollTo( $target );
	            
	            }

	        });

	    },

	    scrollTo : function( selector ){

	        var $target = typeof( selector ) === 'object' ? selector : $( selector );            
	        if( !$target.length ) return;

	        var newY = $target.offset().top;
	        var actualY = _window.scrollTop();
	        var distance = actualY - newY;
	        if( distance < 0 ) distance *= -1;

	        var time = ( 600 - ( distance * 0.1 ) );
	        if( time < 600 ) time = 600;
	        
	        var offset = $target.data( 'offset' );
	        if( offset ){

	            try {

	                var 
	                    _json = JSON.parse( offset.split( "'" ).join( '"' ) )
	                    _offset = false,
	                    _vWidth = Navigation.vWidth()
	                ;

	                $.each( _json.breaks, function( _break ){
	                    if( _vWidth <= _break ){
	                        _offset = parseInt( this );
	                        return false;
	                    }
	                });

	                offset = ( !_offset ) ? parseInt( _json.initial ) : _offset;

	            }
	            catch( e ){
	                var _offset = parseInt( offset );
	                offset = _offset > 0 ? _offset : 0;
	            }

	        }
	        else {
	            offset = 0;
	        }

	        $( 'html, body' ).animate({
	            scrollTop: ( newY - ( offset + 30 ) )
	        }, time );      

	    },

	    vWidth : function vWidth(){
	        return Math.max(document.documentElement.clientWidth, window.innerWidth || 0);
	    }
		
	};

	$(function(){
		Navigation.init();
	});

})(jQuery);
